import React from 'react';
import { useNavigate } from 'react-router-dom';




const CreateCoursesAd = () => {
  const navigate = useNavigate();

  return (
    <div className="image-screen" onClick={() => navigate('/MyAccount')}>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '0',
          paddingBottom: '56.25%',
          overflow: 'hidden',
        }}
      >
        <img
          src="https://ghummantech-website.s3.us-east-2.amazonaws.com/CreateCourses.gif"
          alt="Friday Programming Class"
          style={{ width: '100%', maxWidth: '100%', boxSizing: 'border-box' }}
        />
      </div>
    </div>
  );
};

export default CreateCoursesAd;
