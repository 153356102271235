import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Thumb from "../../../Thumb";
import { formatPrice } from "../../../../services/util";
import { useNavigate } from "react-router-dom";

const Product = ({ product, history, currency }) => {
  const navigate = useNavigate();
  product.quantity = 1;

  let formattedPrice = formatPrice(product.price, product.currencyId);

  const goToProduct = (product) => {
    navigate({
      pathname: `/Categories/Product/${product.sku}`,
      state: { productClicked: product },
    });
  };

  return (
    <div
      className="shelf-item"
      onClick={() => goToProduct(product)}
      data-sku={product.sku}
    >
      <Thumb
        classes="shelf-item__thumb2"
        // src={require(`../../../../static/products/GhummanTech/${product.sku}_1.jpg`)}
        src={product.images[0]}
        alt={product.title}
      />
      <p style={{ margin: "10px", fontSize: "18px", fontFamily: "fantasy" }}>
        {product.title}
      </p>
      <div style={{ color: "red" }}>-----</div>
      <div>
        <div className="val">
          <small>
            $<span> </span>
          </small>
          <b>{formattedPrice} </b>
          {product.included_in_membership ? "for 3 months" : "per month"}
        </div>
      </div>
      {product.included_in_membership === 1 && (
        <div>Included in Membership</div>
      )}
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
};

export default connect(null, null)(Product);
