import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBaseUrl } from '../Utils/getBaseUrl';
import Spinner from '../Spinner';
import { TextField, Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { withAlert } from 'react-alert';
import './style.css';

function CreateNewCourseDetails(props) {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    courseName: '',
    courseDescription: '',
    categories: {
      kids: false,
      programming: false,
      language: false,
      certifications: false,
      live: false,
    },
    images: [null, null, null],
    timing: '',
    instructor: '',
    zoomLink: '',
    monthlyCost: '',
    courseVideoLink: '', // New field for course video link
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, categories: { ...formData.categories, [name]: checked } });
  };

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    if (file && file.size <= 1 * 1024 * 1024) { // 1 MB limit
      const newImages = [...formData.images];
      newImages[index] = file;
      setFormData({ ...formData, images: newImages });
    } else {
      setErrors({ ...errors, images: 'Each image must be less than 1MB' });
    }
  };

  const showSuccessMessageAndNavigate = () => {
    props.alert.show('Course Created Successfully', {
      timeout: 2000,
      type: 'success',
      onClose: () => {
        navigate('/MyAccount');
      }
    });
  };

  const handleSubmit = async () => {
    setErrors({});
    const errors = {};

    if (!formData.courseName.trim()) {
      errors.courseName = 'Course name is required';
    } else if (formData.courseName.length > 100) {
      errors.courseName = 'Course name cannot be more than 100 characters';
    }

    if (!formData.courseDescription.trim()) {
      errors.courseDescription = 'Course description is required';
    } else if (formData.courseDescription.length > 1000) {
      errors.courseDescription = 'Course description cannot be more than 1000 characters';
    }

    const selectedCategories = Object.keys(formData.categories).filter(category => formData.categories[category]);
    if (selectedCategories.length === 0) {
      errors.categories = 'At least one category must be selected';
    }

    if (formData.images.every(image => image === null)) {
      errors.images = 'At least one image is required';
    }

    if (!formData.timing.trim()) {
      errors.timing = 'Timing is required';
    }

    if (!formData.instructor.trim()) {
      errors.instructor = 'Instructor is required';
    }

    if (!formData.zoomLink.trim()) {
      errors.zoomLink = 'Zoom link is required';
    }

    if (!formData.monthlyCost.trim()) {
      errors.monthlyCost = 'Monthly cost is required';
    } else if (isNaN(formData.monthlyCost) || formData.monthlyCost < 0 || formData.monthlyCost > 1000) {
      errors.monthlyCost = 'Monthly cost must be between 0 and 1000';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      setIsLoading(true);
      const baseUrl = getBaseUrl();
      const formDataToSend = new FormData();
      formDataToSend.append('course_name', formData.courseName);
      formDataToSend.append('course_description', formData.courseDescription);
      selectedCategories.push('courses'); // Add 'courses' category
      formDataToSend.append('categories', JSON.stringify(selectedCategories));
      formData.images.forEach((image, index) => {
        if (image) {
          formDataToSend.append(`image_${index}`, image);
        }
      });
      formDataToSend.append('meetingDetails', JSON.stringify({
        timing: formData.timing,
        instructor: formData.instructor,
        zoomLink: formData.zoomLink,
      }));
      formDataToSend.append('monthly_cost', formData.monthlyCost);
      formDataToSend.append('course_video_link', formData.courseVideoLink); // Append course video link
      formDataToSend.append('user_email', localStorage.getItem("email")); // Append user email
    
      const response = await fetch(`${baseUrl}/api/create-course`, {
        method: 'POST',
        body: formDataToSend,
      });
    
      if (!response.ok) {
        throw new Error('Failed to create course');
      }
    
      setErrorMessage('Course created successfully.');
      await showSuccessMessageAndNavigate();
    } catch (error) {
      setErrorMessage('Failed to create course. Please try again later.');
      console.error('Error creating course:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="form-container">
          <h1 className="header">Create New Course</h1>
          <div className="form">
            <TextField
              name="courseName"
              placeholder="Course Name"
              value={formData.courseName}
              onChange={handleChange}
              className="textField"
            />
            {errors.courseName && <span className="error">{errors.courseName}</span>}
            <TextField
              name="courseDescription"
              placeholder="Course Description"
              value={formData.courseDescription}
              onChange={handleChange}
              multiline
              rows={4}
              className="textField"
            />
            {errors.courseDescription && <span className="error">{errors.courseDescription}</span>}
            <h2 className="subheader">Course Category</h2>
            <FormGroup className="checkboxGroup">
              <FormControlLabel
                control={<Checkbox checked={formData.categories.kids} onChange={handleCheckboxChange} name="kids" />}
                label="Kids"
              />
              <FormControlLabel
                control={<Checkbox checked={formData.categories.programming} onChange={handleCheckboxChange} name="programming" />}
                label="Software Programming"
              />
              <FormControlLabel
                control={<Checkbox checked={formData.categories.language} onChange={handleCheckboxChange} name="language" />}
                label="Spoken Languages"
              />
              <FormControlLabel
                control={<Checkbox checked={formData.categories.certifications} onChange={handleCheckboxChange} name="certifications" />}
                label="Certifications"
              />
              <FormControlLabel
                control={<Checkbox checked={formData.categories.live} onChange={handleCheckboxChange} name="live" />}
                label="Live"
              />
            </FormGroup>
            {errors.categories && <span className="error">{errors.categories}</span>}
            <div className="fileInputContainer">
              <p>You can upload up to 3 images (each less than 1MB)</p>
              {formData.images.map((image, index) => (
                <div key={index} className="fileInputWrapper">
                  <label htmlFor={`image_${index}`} className="fileInputLabel">
                    <input
                      type="file"
                      name={`image_${index}`}
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, index)}
                      style={{ display: 'none' }}
                      id={`image_${index}`}
                    />
                    <Button variant="contained" color="primary" component="span">
                      {image ? <CloudUploadIcon className="uploadIcon" /> : '+'}
                    </Button>
                  </label>
                </div>
              ))}
              {errors.images && <span className="error">{errors.images}</span>}
            </div>
            <TextField
              name="timing"
              placeholder="Timing"
              value={formData.timing}
              onChange={handleChange}
              className="textField"
            />
            {errors.timing && <span className="error">{errors.timing}</span>}
            <TextField
              name="instructor"
              placeholder="Instructor"
              value={formData.instructor}
              onChange={handleChange}
              className="textField"
            />
            {errors.instructor && <span className="error">{errors.instructor}</span>}
            <TextField
              name="zoomLink"
              placeholder="Video Link"
              value={formData.zoomLink}
              onChange={handleChange}
              className="textField"
            />
            {errors.zoomLink && <span className="error">{errors.zoomLink}</span>}
            <TextField
              name="monthlyCost"
              placeholder="Monthly Cost ($)"
              type="number"
              value={formData.monthlyCost}
              onChange={handleChange}
              className="textField"
              inputProps={{ min: 0, max: 1000 }}
            />
            {errors.monthlyCost && <span className="error">{errors.monthlyCost}</span>}
            <TextField
              name="courseVideoLink"
              placeholder="Course Video Link"
              value={formData.courseVideoLink}
              onChange={handleChange}
              className="textField"
            />
            <Button variant="contained" color="primary" onClick={handleSubmit} className="submitButton">
              Create Course
            </Button>
          </div>
          {errorMessage && <p className="errorMessage">{errorMessage}</p>}
        </div>
      )}
    </div>
  );
}

export default withAlert()(CreateNewCourseDetails);